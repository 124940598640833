<template>
  <div>
    <hedaer @setSelect="setItem"></hedaer>
    <div class="Investor item">
      <ul
        class="home-tab wow fadeInUp"
        data-wow-delay="0.3s"
        style="padding-left: 0; padding-right: 0"
      >
        <li :class="{ selectBab: selectBab == 1 }" @click="selectBab = 1">
          战略融资
        </li>
        <li :class="{ selectBab: selectBab == 2 }" @click="selectBab = 2">
          动脉网估值
        </li>
        <li :class="{ selectBab: selectBab == 3 }" @click="selectBab = 3">
          公司治理
        </li>
        <li :class="{ selectBab: selectBab == 4 }" @click="selectBab = 4">
          联系方式
        </li>
      </ul>
      <div class="home-line wow fadeInUp" data-wow-delay="0.3s"></div>
      <div v-show="selectBab == 1">
        <div class="Profile-title wow fadeInUp" data-wow-delay="0.4s">
          战略融资
        </div>
        <div class="Profile-en wow fadeInUp" data-wow-delay="0.5s">
          STRATEGIC FINANCING
        </div>
        <p class="LoveHelpStudents-text wow fadeInUp" data-wow-delay="0.6s">
          2018年1月，华盖资本旗下华盖医疗健康基金牵头组成的投资联合体完成了对中国医美行业植发企业碧莲盛的战略控股投资，总投资金额5亿元。据了解，这是目前华盖资本对医美细分领域最大的一笔战略投资。
        </p>
        <p class="LoveHelpStudents-text wow fadeInUp">
          作为中国毛发健康领域的高品质企业，在华盖资本的强力加持下，碧莲盛继续在在植发技术研发、直营医院建设、服务体系升级、人才吸纳培养等方面并线发展，实现了从传统医院运营模式到毛发健康生态链模式的升级转变，为发友提供先进的毛发技术解决方案。
        </p>

        <img
          src="https://static.drlianzhuren.com/brandWeb/Investor/Investor1.jpg"
          class="wow fadeInUp"
          style="border-radius: 0.1481rem; width: 100%"
          alt=""
        />
      </div>
      <div v-show="selectBab == 2">
        <div class="Profile-title wow fadeInUp">动脉网估值</div>
        <div class="Profile-en wow fadeInUp">SCHOLARLY LOTUS</div>
        <p class="LoveHelpStudents-text wow fadeInUp">
          2022年6月15日，“2022未来医疗100强主榜”发布。我们惊喜地发现，碧莲盛已连续数年闯进“创新医疗服务榜”，并创下估值新高。入选2020届“未来医疗100强”时，其估值仅为20亿元。两年时间，碧莲盛已完成估值翻倍，如今估值达45亿元。
        </p>

        <img
          src="https://static.drlianzhuren.com/brandWeb/Investor/Investor2.jpg"
          class="wow fadeInUp"
          style="border-radius: 0.1481rem; width: 100%"
          alt=""
        />
      </div>
      <div v-show="selectBab == 3">
        <div class="Profile-title wow fadeInUp">公司治理</div>
        <div class="Profile-en wow fadeInUp">MANAGEMENT</div>
        <div class="wow fadeInUp corporate-item">
          <div style="display: flex; align-items: flex-end">
            <div class="corporate-title">尤丽娜</div>
            <div class="corporate-titleEn">YOU LINA</div>
          </div>
          <div class="corporate-line"></div>
          <div class="corporate-position">董事长、创始人</div>
        </div>
        <div class="wow fadeInUp corporate-item">
          <div style="display: flex; align-items: flex-end">
            <div class="corporate-title">刘 争</div>
            <div class="corporate-titleEn">LIU ZHENG</div>
          </div>
          <div class="corporate-line"></div>
          <div class="corporate-position">总经理</div>
        </div>
        <div class="wow fadeInUp corporate-item">
          <div style="display: flex; align-items: flex-end">
            <div class="corporate-title">师晓炯</div>
            <div class="corporate-titleEn">SHI XIAOJIONG</div>
          </div>
          <div class="corporate-line"></div>
          <div class="corporate-position">董事长助理兼副总经理</div>
        </div>
        <div class="wow fadeInUp corporate-item">
          <div style="display: flex; align-items: flex-end">
            <div class="corporate-title">张 琦</div>
            <div class="corporate-titleEn">ZHANG QI</div>
          </div>
          <div class="corporate-line"></div>
          <div class="corporate-position">副总经理</div>
        </div>
        <div class="wow fadeInUp corporate-item" style="margin-bottom: 0">
          <div style="display: flex; align-items: flex-end">
            <div class="corporate-title">吴文跃</div>
            <div class="corporate-titleEn">WU WENYUE</div>
          </div>
          <div class="corporate-line"></div>
          <div class="corporate-position">副总经理</div>
        </div>
      </div>
      <div v-show="selectBab == 4">
        <div class="Profile-title wow fadeInUp">联系方式</div>
        <div class="Profile-en wow fadeInUp">CONTACT INFORMATION</div>
        <div class="contact">
          <div class="contact-title wow fadeInUp">公司地址</div>
          <div class="contact-text wow fadeInUp">
            北京市 大兴区 西红门鸿坤广场B座3号楼5层
          </div>
        </div>
        <div class="contact wow fadeInUp">
          <div class="contact-title">邮箱</div>
          <div class="contact-text">pr@blsgroup.cn</div>
        </div>
        <div
          class="contact wow fadeInUp"
          style="padding-bottom: 0; border: none"
        >
          <div class="contact-title wow fadeInUp">微信公众号</div>
          <div class="contact-text wow fadeInUp">
            <img
              src="https://static.drlianzhuren.com/brandWeb/contactUs/wx.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <Footer @setItem="setItem"></Footer>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      selectBab: 1,
    };
  },
  methods: {
    setItem(a) {
      this.selectBab = a;
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.selectBab = this.$route.params.id + 1;
    }
  },
};
</script>
  
  <style  lang="scss">
.Investor {
  background: #f9fafb;
}
.corporate-item {
  width: 500px;
  height: 173px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 37px 0 0 37px;
  margin-bottom: 20px;
  .corporate-title {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #363636;
  }
  .corporate-titleEn {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #707070;
    margin-left: 10px;
  }
  .corporate-line {
    width: 25px;
    height: 2px;
    background-color: #cdcdcd;
    margin: 25px 0;
  }
  .corporate-position {
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #707070;
  }
}
.contact {
  border-bottom: solid 1px #d5d5d5;
  padding: 38px 0;
  .contact-title {
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #707070;
  }
  .contact-text {
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    margin-top: 17px;
    letter-spacing: 0px;
    color: #3b3b3b;
  }
  img {
    width: 175px;
    height: 175px;
  }
}
</style>